.filter-select {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  background: #ffffff;
  &__inner {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 10px;
  }
  &__field {
    width: 100%;
    outline: none;
    border: none;
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
  }
  &__item {
    padding: 15px 10px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      font-weight: 500;
      color: #992f87;
      transition: all 0.3s;
    }
    &--active {
      font-weight: 500;
      color: #992f87;
    }
  }
}

.custom-input {
  padding: 0 20px 15px 0;
  &__field {
    border: 1px solid #e0e0e0;
    border-radius: 25px;
    padding: 10px 0 10px 20px;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    outline: none;
    width: 100%;
    &::placeholder {
      color: #000000;
    }
  }
}

.autocomplete-org {
  padding: 0 20px 15px 0px;
}

.autocomplete-filter {
  padding-right: 20px;
  width: 100%;
}

.autocomplete {
  position: relative;
  width: 100%;
  &__field {
    border: 1px solid #e0e0e0;
    border-radius: 25px;
    padding: 10px 0 10px 20px;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    outline: none;
    width: 100%;
    &::placeholder {
      color: #000000;
    }
  }
  &__inner {
    position: absolute;
    top: 45px;
    left: 0;
    width: 100%;
    background: #ffffff;
    line-height: 48px;
    border-radius: 25px;
    border: 1px solid #e0e0e0;
    z-index: 100;
  }
  &__item {
    padding: 0 10px;
    font-size: 14px;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      transition: all 0.3s;
      background: #eaeaea;
    }
  }
  &__bottom {
    display: flex;
    margin-top: 13px;
  }
  &__selected {
    background: #fedd00;
    font-size: 14px;
    line-height: 20px;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    margin-right: 12px;
    cursor: pointer;
  }
  &__icon {
    margin-left: 10px;
    color: #ffffff;
    font-size: 15px;
    margin-top: -2px;
  }
}

.custom-select {
  padding: 0 20px 15px 0px;
  &__field {
    border: 1px solid #e0e0e0;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 10px 15px 10px 20px;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    & input {
      display: none;
    }
  }
  &__inner {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    background: #ffffff;
    line-height: 48px;
    border-radius: 25px;
    border: 1px solid #e0e0e0;
    z-index: 100;
  }
  &__item {
    padding: 0 10px;
    font-size: 14px;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      transition: all 0.3s;
      background: #eaeaea;
    }
  }
}

.table-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fedd00;
  &__inner {
    position: absolute;
    top: 54px;
    left: 0;
    width: 100%;
    background: #ffffff;
    border-radius: 25px;
    padding: 5px;
  }
  &__item {
    font-size: 14px;
    line-height: 16px;
    padding: 10px;
    cursor: pointer;
    color: #000000;
    border-radius: 25px;
    transition: all 0.3s;
    &:hover {
      transition: all 0.3s;
      background: #eaeaea;
    }
  }
}

.drag-file {
  background: rgba(106, 209, 227, 0.15);
  border-radius: 25px;
  padding: 10px;
  height: 302px;
  cursor: pointer;
  &__inner {
    border: 1px dashed #6ad1e3;
    border-radius: 15px;
    height: 100%;
    position: relative;
    padding: 20px;
    &-top {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  &__icon {
    margin-bottom: 22px;
  }
  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #6ad1e3;
  }
  &__pic {
    margin-right: 10px;
    position: relative;
    box-shadow: 0px 5px 6px rgba(106, 209, 227, 0.15);
    &-remove {
      position: absolute;
      top: 0;
      right: 0;
      background: #6ad1e3;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px;
      & .remove-pic {
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
      }
    }
  }
}

.calendar-input {
  position: absolute;
  top: 45px;
  left: -8px;
  z-index: 100;
}

.input-disabled {
  background: #ebebe4 !important;
}

.input-error {
  border: 1px solid #ff6666;
}

.input-valid {
  border: 1px solid #fedd00;
}

.input-error-text {
  color: #ff6666;
  padding-left: 20px;
  font-size: 12px;
  line-height: 20px;
  display: block;
}

@media (max-width: 768px) {
  .custom-input {
    padding-right: 0;
  }
  .custom-select {
    padding-right: 0;
  }
  .autocomplete-org {
    padding-right: 0;
  }
}
