.header {
  background: #fff;
  padding: 0 85px 10px 85px;
  height: 445px;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.6s ease-in;
  &__img {
    // width: 100%;
    max-height: 390px;
  }
  &__title {
    font-size: 48px;
    font-weight: 300;
    color: #992f87;
    margin-bottom: 15px;
  }
  &__dscr {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 22px;
  }
  &__line {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    margin: 20px 0 15px 0;
  }
  &__left {
    position: relative;
    & .indicators {
      height: 10px;
      position: absolute;
      left: 315px;
      bottom: 10px;
      z-index: 1;
      margin-top: 0 !important;
      & div {
        background: rgba(106, 209, 227, 0.3) !important;
        width: 10px !important;
        height: 10px !important;
        &:hover {
          background: rgba(106, 209, 227, 0.3) !important;
        }
      }
      & div.active {
        background: #6ad1e3 !important;
        &:hover {
          background: #6ad1e3 !important;
        }
      }
    }
  }
  &__right {
    position: relative;
    & .indicators {
      height: 10px;
      position: absolute;
      left: 125px;
      top: 365px;
      z-index: 1;
      margin-top: 0 !important;
      & div {
        background: rgba(153, 47, 135, 0.3) !important;
        width: 10px !important;
        height: 10px !important;
        &:hover {
          background: rgba(153, 47, 135, 0.3) !important;
        }
      }
      & div.active {
        background: #992f87 !important;
        &:hover {
          background: #992f87 !important;
        }
      }
    }
    &-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    & div {
      height: 100%;
      & > {
        height: 100%;
      }
    }
    &-row {
      display: flex;
      flex-wrap: wrap;
      & div {
        text-align: right;
      }
    }
    &-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  &__counter {
    color: #6ad1e3;
    font-size: 48px;
    line-height: 56px;
    font-weight: normal;
    margin-bottom: 20px;
  }
  & button {
    box-shadow: none !important;
  }
  &__counter-dscr {
    font-size: 14px;
    line-height: 20px;
  }
  &__btn-inner {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  &__btn {
    &--blue {
      margin-bottom: 10px !important;
    }
    &--violet {
      margin-bottom: 25px !important;
    }
  }
}

.header-hidden {
  margin-top: -410px;
}

// @media (min-width: 1024px) and (max-width: 1439px) {
//   .header {
//     &__right {
//       left: auto;
//       right: -50%;
//     }
//   }
// }

@media (max-width: 1439px) {
  .header {
    padding: 0;
    height: 640px;
    padding-left: 20px;
    padding-top: 20px;
    &__line {
      display: none;
    }
    &__img {
      width: 284px;
      height: 231px;
      position: absolute;
      top: 0;
    }
    &__title {
      font-size: 25px;
      line-height: 28px;
    }
    &__counter {
      text-align: left;
      font-size: 22px;
      margin: 0;
    }
    &__counter-dscr {
      text-align: left;
      font-size: 9.5px;
      line-height: 12px;
    }
    &__btn-inner a {
      height: 48px !important;
    }
    &__btn {
      &--blue {
        width: 210px;
        min-width: unset !important;
      }
      &--violet {
        position: absolute !important;
        left: 50px !important;
      }
      &--link {
        margin-top: 40px;
      }
    }
    &__left {
      & .indicators {
        bottom: 22px;
      }
    }
    &__right {
      &-inner {
        justify-content: space-between;
      }
      & .indicators {
        left: 5px;
        top: 198px;
      }
      &-wrap {
        flex-direction: row;
        flex-wrap: wrap;
      }
      &-row {
        width: calc(50% - 10px);
        div {
          min-height: 110px;
          width: 100%;
        }
      }
    }
  }
  .header-hidden {
    margin-top: -612px;
  }
}
