.toggle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  bottom: 0;
  &__button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 46px;
    height: 46px;
    border: 1px solid rgba(0, 0, 0, 0.15);;
    border-radius: 50%;
    cursor: pointer;
  }
  &__arrow {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 19px;
    & span {
      display: block;
      position: absolute;
      transition: all .6s ease-in;
      bottom: 0;
      background: #6AD1E3;
      width: 12px;
      height: 2px;
      &:first-child{
        transform: rotate(-45deg);
        left: 0;
      }
      &:last-child{
        transform: rotate(45deg);
        right: 0;
      }
    }
    &--reverse {
      & span {
        top: 0;
        &:first-child {
          transform: rotate(45deg);
          left: 0;
        }
        &:last-child {
          transform: rotate(-45deg);
          right: 0;
        }
      }
    }
  }
}