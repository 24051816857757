.org-details {
  padding: 35px 65px 40px 45px;
  &__title {
    font-weight: bold;
    font-size: 36px;
    line-height: 20px;
    margin-bottom: 35px;
  }
  &__subtitle {
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
    color: #992F87;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
}