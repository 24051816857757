.pagination {
  margin-top: 50px;
  & .Mui-selected {
    color: #33BCCD;
    background: #FFFFFF !important;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.05) !important;
  }
  & .MuiPaginationItem-ellipsis {
    background: none !important;
  }
  & .MuiPaginationItem-root {
    background: #F2F2F2;
  }
}