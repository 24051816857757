.table-filters {
  margin: 36px 0;
  padding: 0 0 0 20px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.05);
  background: white;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  &__inner {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    font-size: 14px;
    position: relative;
    cursor: pointer;
    height: 100%;
    &:after {
      content: "";
      border-right: 0.5px solid rgba(0, 0, 0, 0.15);
      border-left: 0.5px solid rgba(0, 0, 0, 0.15);
      height: 30px;
      position: absolute;
      left: 0;
    }

    &:last-child,
    &:first-child {
      &:after {
        display: none;
      }
    }
  }
  &__field {
    border: none;
    outline: none;
    height: 50px;
    font-size: 14px;
    background: transparent;
    width: 100%;
    &::placeholder {
      color: black;
    }
  }
  &__btn {
    flex-grow: 1;
    height: 46px;
    background: rgba(255, 222, 0, 0.2);
    border-radius: 24px;
    margin-left: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-right: 2px;
    font-size: 14px;
    color: #c59900;
    text-transform: uppercase;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      margin-left: 15px;
    }
  }
}

@media (max-width: 768px) {
  .table-filters {
    &__btn {
      position: fixed;
      width: 120px;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
