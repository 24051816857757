.statistics {
  padding: 40px 50px 0 50px;
  & .col {
    margin-bottom: 40px;
  }
  &__item {
    background: #ffffff;
    box-shadow: 0px 8px 23px rgba(0, 0, 0, 0.11);
    border-radius: 47px;
    padding: 50px 50px 50px 35px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: flex-end;
  }
  &__right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-item {
      display: flex;
      justify-content: flex-end;
      &--top {
        margin-bottom: 100px;
      }
    }
  }
  &__count {
    text-align: right;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &--top {
      margin-bottom: 100px;
    }
    &--left {
      margin-right: 100px;
    }
  }
  &__title {
    font-size: 48px;
    font-weight: 300;
    color: #992f87;
  }
  &__counter {
    color: #6ad1e3;
    font-size: 48px;
    line-height: 56px;
    font-weight: normal;
  }
  &__text {
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
  }
  &__features {
    text-transform: uppercase;
  }
}

@media (max-width: 768px) {
  .statistics {
    padding: 20px;
    .row {
      display: flex;
      flex-direction: column;
    }
    &__item {
      flex-direction: column;
      padding: 20px;
    }
    &__right {
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 20px;
      &-item {
        &--top {
          margin-bottom: 20px;
        }
      }
    }
    &__count {
      &--top {
        margin: 0;
        width: 50%;
      }
    }
    &__title {
      font-size: 23px;
      align-self: start;
    }
    &__counter {
      font-size: 32px;
      line-height: 37px;
    }
    &__text {
      font-size: 13px;
      line-height: 17px;
    }
  }
}
