.noAuthOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.noAuthWrapper {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  padding: 100px 40px;
  padding-bottom: 50px;
  width: 100%;
  background-color: #fff;
  overflow: auto;
  z-index: 4;
}

.noAuthText {
  width: 200px;
  margin: 0 auto;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #992f87;
}

.noAuthBtn {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  text-decoration: none;
  margin: 25px auto;
  height: 50px;
  border-radius: 25px;
}

.noAuthCloseBtnWrapper {
  width: 80px;
  height: 80px;
  border: 1px solid #e5e5e5;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.noAuthCloseBtnArea {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.noAuthCloseBtn:after,
.noAuthCloseBtn:before {
  content: "";
  display: block;
  width: 20px;
  height: 1px;
  background-color: #992f87;
  transform: rotate(45deg);
}

.noAuthCloseBtn:before {
  transform: rotate(-45deg);
}

@media (min-width: 768px) {
  .noAuthWrapper {
    width: 80%;
    left: 10%;
  }

  .noAuthText {
    width: 300px;
  }
}

@media (min-width: 1024px) {
  .noAuthWrapper {
    width: 60%;
    left: 20%;
  }

  .noAuthText {
    width: 400px;
  }
}

@media (min-width: 1920px) {
  .noAuthWrapper {
    width: 40%;
    left: 30%;
  }

  .noAuthText {
    width: 500px;
  }
}
