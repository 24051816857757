$blue: #6ad1e3;
$violet: #992f87;

.button {
  min-width: 280px !important;
  display: flex !important;
  justify-content: space-between !important;
  &-blue {
    background-color: $blue !important;
    color: #fff !important;
  }
  &-violet {
    background-color: $violet !important;
    color: #fff !important;
  }
}

.button-send {
  border: none;
  background: #992f87;
  width: 280px;
  height: 46px;
  left: 580px;
  top: 884px;
  border-radius: 25px;
  color: white;
  text-transform: uppercase;
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: calc(100vw / 2 - 290px);
    height: 1px;
    left: 130px;
    background: rgba(153, 47, 135, 0.4);
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: calc(100vw / 2 - 290px);
    height: 1px;
    right: 130px;
    background: rgba(153, 47, 135, 0.4);
  }
}

.profile-button {
  &:before {
    width: calc(100vw / 2 - 200px);
  }
  &:after {
    width: calc(100vw / 2 - 250px);
    margin-right: -130px;
  }
}

.button-disabled {
  background: #dddddd !important;
  color: #ffffff !important;
  cursor: auto !important;
  border: none !important;
}
