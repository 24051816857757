.profile {
  padding: 60px 45px 100px 45px;
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__caption {
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
    color: #992f87;
    margin-bottom: 21px;
  }
  &__row {
    margin-bottom: 60px;
  }
  & .row {
    padding-left: 15px;
  }
  & .col {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 768px) {
  .profile {
    & div {
      width: 100%;
    }
    &__row,
    .row2 {
      display: flex;
      flex-direction: column;
    }
    &__caption {
      font-size: 22px;
      line-height: 26px;
    }
  }
}
