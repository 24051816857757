.filters-popup {
  border-radius: 30px;
  background: #ffffff;
  padding-bottom: 30px;
  &__label {
    font-weight: bold;
    &--normal {
      font-weight: normal;
    }
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px 15px 30px;
    &--left {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
    &--right {
      display: flex;
    }
  }
  &__btn {
    min-width: 220px;
    padding: 8px 0;
    border-radius: 25px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-transform: uppercase;
    &--clear {
      color: #992f87;
      border: 1px solid #e0e0e0;
      background: transparent;
      margin-right: 10px;
    }
    &--select {
      background: #6ad1e3;
      color: #ffffff;
      border: none;
    }
  }
  & .MuiPaper-elevation1 {
    box-shadow: none;
  }
  & .MuiExpansionPanel-root.Mui-expanded {
    margin: 0;
  }
  & .MuiExpansionPanelDetails-root {
    padding: 25px;
  }
}

@media (max-width: 768px) {
  .filters-popup {
    &__btn {
      min-width: 40px;
    }
  }
}
