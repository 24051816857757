.test-mode {
  position: fixed;
  z-index: 8;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  height: 20px;
  background: #6ad1e3;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.navigation {
  height: 80px;
  margin-top: 20px;
  left: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  z-index: 9;

  .search {
    position: relative;
    margin-left: 170px;
    padding-left: 35px;
    margin-top: -16px;
    max-width: 333px;
    width: 100%;
    @media (max-width: 767px) {
      margin-left: 90px;
      max-width: calc(100% - 175px);
    }
    svg {
      position: absolute;
      font-size: 28px;
      color: #6ad1e3;
      bottom: 0;
      left: 0;
    }
    &-input {
      width: 100%;
      label {
        font-size: 14px;
        color: black;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        &.Mui-focused {
          color: #333 !important;
        }
      }
      .MuiInput-underline:before {
        border-bottom: 0 solid #333;
      }
      &.underline {
        .MuiInput-underline:before {
          border-bottom: 1px solid #333;
        }
        .MuiInput-underline:before {
          border-bottom: 1px solid #333;
        }
        .MuiInput-underline:hover:not(.Mui-disabled):before {
          border-bottom: 1px solid #333;
        }
      }
      .MuiInput-underline:after {
        border-bottom: 1px solid #333;
      }
      .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: 0 solid #333;
      }
      input {
        font-size: 14px;
        width: 100%;
      }
    }
  }

  &-menu {
    padding-right: 90px;
    display: flex;
    a,
    .menu-item {
      color: black;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      text-decoration: none;
      display: flex;
      align-items: center;
      padding: 0 20px;
      background: #fff;
      border-right: 0.5px solid rgba(0, 0, 0, 0.15);
      border-left: 0.5px solid rgba(0, 0, 0, 0.15);
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
        padding-right: 0;
      }
      &:hover {
        color: #992f87;
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
      }
      svg {
        margin-left: 15px;
        margin-bottom: 4px;
        rect {
          fill: #992f87;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .navigation {
    &-menu {
      padding-right: 10px;
      a,
      .menu-item {
        border: none;
        width: 50px;
        padding: 0;
        svg {
          margin-left: 0;
          margin: 0 15px;
        }
      }
    }
    &-burger {
      position: fixed;
      width: 20px;
      height: 20px;
      left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 2;
    }
    &-burgerMenuIcon {
      width: 20px;
      height: 1.5px;
      background-color: #992f87;
      position: fixed;
    }
    &-burgerMenuIcon:before,
    &-burgerMenuIcon:after {
      content: "";
      display: block;
      width: 20px;
      height: 1.5px;
      background-color: #992f87;
      position: absolute;
      transition: background-color 0.2s linear, transform 0.2s ease-in,
        top 0.2s linear 0.2s;
    }
    &-burgerMenuIcon:before {
      top: -6px;
    }
    &-burgerMenuIcon:after {
      top: 6px;
    }
    &-burgerMenuOverlay {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.2);
      width: 100vw;
      height: 100vh;
      overflow: auto;
      z-index: 2;
    }
    .search {
      margin-top: 0;
      margin-left: 60px;
    }
  }
}
