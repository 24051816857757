.login {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-close {
    position: absolute;
    right: 50px;
    top: 50px;

    &.white {
      svg > line {
        stroke: white;
      }
    }
  }

  &-body {
    width: 600px;
    padding-bottom: 150px;
    text-align: center;

    img {
      width: 27vh;
      margin-bottom: 30px;
    }

    h2 {
      font-weight: 200;
      font-size: 32px;
      padding-bottom: 25px;
    }

    > form {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;

      .input:not(.select) {
        position: relative;
        padding-bottom: 17px;

        > input {
          width: 100%;
          height: 42px;
          border-radius: 25px;
          outline: none;
          padding: 0 20px;
          font-size: 14px;
          font-weight: normal;
          margin-left: -20px;
          border: 1px solid #e0e0e0;

          &.filled {
            border: 1px solid #fedd00;
          }

          &.error {
            border: 1px solid #ff6666;
          }

          &::placeholder {
            color: black;
          }
        }
      }

      p.error {
        position: absolute;
        color: #ff6666;
        font-size: 11px;
        line-height: 18px;
        font-weight: normal;
      }

      button {
        width: 100px;
        height: 45px;
        background: #fedd00;
        border: none;
        border-radius: 25px;
        position: relative;
        cursor: pointer;
        outline: none;

        &:disabled {
          background: rgba(254, 221, 0, 0.2);
          cursor: default;
        }

        &:before {
          content: "";
          pointer-events: none;
          display: block;
          background: rgba(0, 0, 0, 0.15);
          width: 230px;
          height: 1px;
          position: absolute;
          right: calc(100% + 10px);
          top: calc(50% - 1px);
          cursor: default;
        }

        &:after {
          content: "";
          pointer-events: none;
          display: block;
          background: rgba(0, 0, 0, 0.15);
          width: 230px;
          height: 1px;
          position: absolute;
          left: calc(100% + 10px);
          top: calc(50% - 1px);
          cursor: default;
        }

        svg {
          padding-top: 2px;
        }
      }
    }

    .socials {
      > svg {
        margin: 20px 10px;
        cursor: pointer;
      }
    }

    .move-to {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      h3 {
        font-weight: normal;
        font-size: 24px;
        line-height: 40px;
        color: #992f87;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 15px;
      }

      &--btn {
        position: relative;
        height: 70px;

        > svg {
          position: absolute;
          height: 70px;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);

          &.white > path {
            fill: white;
          }
        }

        button {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          background: #992f87;
          box-shadow: none;

          &:hover {
            background: #8a2a79;
          }
        }
      }
    }
  }
}

.register {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #992f87;

  &-close {
    position: absolute;
    right: 50px;
    top: 50px;

    svg > line {
      stroke: white;
    }
  }

  &-body {
    width: 100%;
    max-width: 800px;
    padding-bottom: 150px;
    text-align: center;

    img {
      width: 15vh;
      margin-bottom: 30px;
    }

    h2 {
      font-weight: 200;
      font-size: 32px;
      color: white;
      padding-bottom: 25px;
    }
    > h3 {
      padding: 50px 0;
      font-weight: 200;
      font-size: 18px;
      color: white;
      > span {
        color: #fedd00;
        cursor: pointer;
      }
    }

    > form {
      text-align: center;

      .input:not(.select) {
        position: relative;
        padding: 0 10px 17px 10px;

        > input {
          width: calc(100% - 40px);
          height: 40px;
          border-radius: 25px;
          outline: none;
          padding: 0 20px;
          font-size: 14px;
          font-weight: normal;
          border: 2px solid #fff;
          transition: all 0.3s ease;
          &:focus {
            background: transparent;
            color: white;
            &::placeholder {
              color: white;
            }
          }

          &.filled {
            border: 2px solid #fedd00;
          }

          &.error {
            border: 2px solid #ff6666;
          }

          &::placeholder {
            color: black;
          }
        }
      }

      p.error {
        position: absolute;
        color: #ff6666;
        font-size: 11px;
        line-height: 18px;
        font-weight: normal;
        padding-left: 20px;
      }

      button {
        width: 100px;
        height: 45px;
        background: #fedd00;
        border: none;
        border-radius: 25px;
        position: relative;
        cursor: pointer;
        outline: none;

        &:disabled {
          background: rgba(255, 255, 255, 0.2);
          cursor: default;
        }

        &:before {
          content: "";
          pointer-events: none;
          display: block;
          background: rgba(255, 255, 255, 0.3);
          width: 230px;
          height: 1px;
          position: absolute;
          right: calc(100% + 10px);
          top: calc(50% - 1px);
          cursor: default;
        }

        &:after {
          content: "";
          pointer-events: none;
          display: block;
          background: rgba(255, 255, 255, 0.3);
          width: 230px;
          height: 1px;
          position: absolute;
          left: calc(100% + 10px);
          top: calc(50% - 1px);
          cursor: default;
        }

        svg {
          padding-top: 2px;
        }
      }
    }

    .socials {
      h2 {
        font-size: 22px;
        color: white;
        font-weight: 200;
        padding-bottom: 0;
        padding-top: 25px;
      }
      > svg {
        margin: 20px 10px;
        cursor: pointer;
      }
    }

    .move-to {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      h3 {
        font-weight: normal;
        font-size: 24px;
        line-height: 40px;
        color: white;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 15px;
        color: white;
      }

      &--btn {
        position: relative;
        height: 70px;

        > svg {
          position: absolute;
          height: 70px;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);

          > path {
            fill: white;
          }
        }

        button {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          background: white;
          box-shadow: none;

          &:hover {
            background: #efefef;
          }
        }
      }
    }
  }
}

.input.select,
.datePicker .MuiFormControl-root.MuiTextField-root {
  position: relative;
  padding: 0 10px 17px 10px;

  .MuiInputBase-root {
    margin: 0;
  }

  .MuiFormLabel-root {
    display: none;
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }

  input {
    width: calc(100% - 40px);
    pointer-events: none;
    background: white;
    height: 40px;
    border-radius: 25px;
    outline: none;
    padding: 0 20px;
    font-size: 14px;
    font-weight: normal;
    border: 2px solid #fff;
    cursor: pointer;

    &.filled {
      border: 2px solid #fedd00;
    }

    &.error {
      border: 2px solid #ff6666;
    }

    &::placeholder {
      color: black;
    }

    &:disabled {
      background: transparentize(white, 0.5);
      border: 2px solid transparent;
      &::placeholder {
        color: #777;
      }
    }
  }

  > ul.list {
    position: absolute;
    list-style-type: none;
    top: 30px;
    width: calc(100% - 15px);
    background: transparent;
    z-index: -1;
    transition: all 0.15s ease;
    transform: scale(0);
    max-height: 100px;
    overflow-y: auto;
    border-radius: 12px;
    padding: 10px 0;

    &.open {
      top: 0;
      background: #333;
      transform: scale(1);
      z-index: 2;
    }

    > li {
      width: 100%;
      transition: all 0.2s ease;
      color: #eee;
      cursor: pointer;
      font-size: 14px;
      font-weight: 300;
      line-height: 28px;
      &:hover {
        background: #444;
      }
    }
  }

  .decoration {
    position: absolute;
    display: inline-block;
    top: 10px;
    cursor: pointer;
    right: 25px;
  }
}

.datePicker {
  position: relative;
  > .input {
    position: relative;
    > input {
      pointer-events: none;
    }
    .decoration {
      position: absolute;
      display: inline-block;
      top: 13px;
      cursor: pointer;
      right: 23px;
    }
  }
  > .MuiFormControl-root.MuiTextField-root {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 16px);

    input {
      border: 2px solid #fedd00;
    }
  }
  .decoration {
    position: absolute;
    display: inline-block;
    top: 13px;
    cursor: pointer;
    right: 23px;
  }
}

.radio {
  > .MuiFormControl-root {
    flex-direction: row;
    display: flex;
    padding-left: 20px;
    > .MuiFormGroup-root {
      flex-direction: row;
      > label {
        color: white;
        .MuiRadio-colorSecondary {
          color: white;
          &.Mui-checked {
            color: white;
          }
        }
      }
    }
  }
}
