.main {
  &__scrollable {
    padding: 70px 90px;
    transition: all 0.6s ease-in;
    @media (max-width: 767px) {
      padding: 50px 15px;
    }
  }
  &__count {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__caption {
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
    @media (max-width: 767px) {
      font-size: 30px;
      line-height: 36px;
    }
  }
  &__counter {
    display: flex;
    align-items: center;
    span {
      font-weight: normal;
      font-size: 36px;
      line-height: 42px;
      color: #6ad1e3;
    }
    svg {
      margin-left: 20px;
    }
  }
}
