.table {
  text-align: center;
  &__cell {
    display: flex;
    align-items: center;
    &-link {
      margin-right: 11px;
    }
  }
  &__mail {
    display: flex;
    &-link {
      margin-left: 9px;
      text-decoration: none;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
  }
  &__title {
    font-weight: 500 !important;
    color: #000000;
  }
  &__candidates {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-count {
      background: #FEDD00;
      border-radius: 50%;
      width: 43px;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}

.table-wrapper {
  display: flex;
  justify-content: center;
}