.sidebar {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 80px;
  background: #992f87;
  border-radius: 0 40px 0 0;
  z-index: 10;
  transition: 0.3s ease-out;
  &--open {
    width: 240px;
  }
  &__logo {
    position: absolute;
    right: 0;
    margin-left: -55px;
    height: 80px;
    background: #992f87;
    border-radius: 0 40px 40px 0;

    &--open {
      transition: all 0.3s ease-in-out 0.3s;
      margin: 0;
    }
    & img {
      margin: 12px 20px;
    }
    &:hover {
      cursor: pointer;
    }
    & a label {
      position: absolute;
      left: -130%;
    }
  }
  &__menu {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    margin-left: 30px;
    transition: 0.3s ease-in-out;
    & button {
      display: block;
      width: 56px;
      background: #992f87;
      box-shadow: none;
      margin: 10px 0;
      &:hover {
        background: #8a2a79;
      }
      &:active {
        box-shadow: none;
      }
    }
    & div {
      width: 180px;
    }
    &--closed {
      margin-left: 5px;
      & div {
        width: 80px;
      }
    }
  }

  &__bottom {
    position: absolute !important;
    bottom: 15px;
  }
  &__label {
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    cursor: pointer;
  }
  &__customLink {
    position: absolute;
    transform: translateX(-100%);
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  &__customLink--open {
    transform: translateX(0);
    opacity: 1;
    transition: all 0.3s ease-in-out 0.3s;
  }
  &__link {
    height: 30px;
    margin: 22px 0;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  &__closeBtnArea {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 44px;
    left: 14px;
  }
  &__closeBtn {
    width: 20px;
    height: 1px;
    background-color: transparent;
  }
  &__closeBtn:before,
  &__closeBtn:after {
    content: "";
    display: block;
    width: 20px;
    height: 1.5px;
    background-color: #992f87;
    position: absolute;
    transform: rotate(45deg);
    top: 15px;
    left: 7px;
    background-color: #ffffff;
  }
  &__closeBtn:after {
    transform: rotate(-45deg);
  }
}

@media (max-width: 767px) {
  .sidebar {
    position: absolute;
    top: 20px;
    &__logo {
      margin-top: 60px;
    }
  }
}

@media (min-width: 1920px) {
  .sidebar {
    width: 130px;
    &--open {
      width: 260px;
    }
    &__menu {
      margin-left: 30px;
    }
  }
}
