@media (max-width: 767px) {
  .gridContainer {
    flex-direction: column;
    align-items: center;
  }

  .gridItem {
    max-width: unset !important;
    width: 375px;
  }
}
