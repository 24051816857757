.organisation {
  padding: 110px 130px 94px 130px;
  &__close-icon {
    position: absolute;
    right: 60px;
    top: 60px;
  }
  &__title {
    color: #992f87;
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 41px;
    text-transform: uppercase;
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .organisation {
    padding: 20px;
    padding-top: 100px;
    &__close-icon {
      right: 20px;
      top: 20px;
    }
    &__title {
      font-size: 25px;
      line-height: 29px;
    }
  }
}
