.candidates {
  background: #992F87;
  height: 100%;
  padding: 40px;
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 115px;
  }
  &__title {
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
    color: #FFFFFF;
  }
}