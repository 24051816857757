.plus-menu {
  > button {
    position: fixed;
    width: 70px;
    height: 70px;
    right: 30px;
    bottom: 40px;
    background: #992f87;
    color: white;
    z-index: 12;
    svg {
      transition: all 0.4s ease;
    }

    &:hover {
      background: #992f87;
    }
  }

  &.open {
    > button svg {
      transform: rotate(135deg);
    }
  }

  > .bg {
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 0.4s ease;
    overflow: hidden;

    &.close {
      top: 100vh;
    }

    > .plus-menu--item {
      position: absolute;
      right: 40px;
      display: flex;
      align-items: center;
      > p {
        max-width: 125px;
        text-align: right;
        padding-right: 20px;
        font-size: 15px;
        line-height: 17px;
        font-weight: bold;
        color: #fff;
      }
      > button {
        width: 50px;
        height: 50px;
        background: white;
      }
      &:nth-child(1) {
        bottom: 194px;
      }
      &:nth-child(2) {
        bottom: 130px;
      }
      // &:nth-child(1) {
      //   bottom: 258px;
      // }
      // &:nth-child(2) {
      //   bottom: 194px;
      // }
      // &:nth-child(3) {
      //   bottom: 130px;
      // }
    }
  }
}
