@media (max-width: 1440px) {
  .gridContainer {
    align-items: center;
  }

  .gridContainerLeft {
    flex-wrap: nowrap !important;
  }

  .fade {
    width: 100%;
  }
}
